/// <reference lib="webworker" />

// Names of the two caches used in this version of the service worker.
// Modify the PRECACHE version will trigger the install event again.

const PRECACHE = BC_VERSION;
const STATIC_RUNTIME_CACHE = "static_runtime";

// A list of local resources we always want to be cached.
const PRECACHE_URLS = [
  "index.html",
  "./", // Alias for index.html
  "./favicon.ico",
  "/bikecenter.mobile.css",
  "/bikecenter.mobile.js"
];

// a list of api paths we don't want to be cached by the service worker
const API_PATHS = ["/api/", "/service/"];

const logger = {
  info: <T>(...message: T[]) => console.info("[SW]", ...message),
  warn: <T>(...message: T[]) => console.warn("[SW]", ...message)
};

const handleInstallEvent = (event: ExtendableEvent) => {
  logger.info(`Found the latest app version: ${BC_VERSION}. Installing ...`);

  const currentCaches = [PRECACHE, STATIC_RUNTIME_CACHE];

  const addToPrecache = async (precache: Cache, urls: string[]) => {
    const responses = await Promise.all(
      urls.map(async url => {
        try {
          return {
            url,
            response: await fetch(url, { cache: "reload" })
          };
        } catch {
          logger.info(
            `Precaching went wrong for ${url}. The device is probably offline, resource will be cached by the runtime cache.`
          );
        }
      })
    );

    await Promise.all(
      responses
        .filter((response): response is { url: string; response: Response } => !!response)
        .map(async ({ url, response }) => {
          await precache.put(url, response);
        })
    );
  };

  const deleteOldPrecache = async () => {
    const cacheKeys = await caches.keys();
    const cachesToDelete = cacheKeys.filter(cacheKey => !currentCaches.includes(cacheKey));
    await Promise.all(cachesToDelete.map(async cacheToDelete => await caches.delete(cacheToDelete)));
  };

  event.waitUntil(
    (async () => {
      const precache = await caches.open(PRECACHE);

      // we have a netork connection, so we can delete the old precache
      // and activate this new service worker immediately
      // to ensure precaching assets won't be taken from the old precache
      // since cache.addAll is broken
      await self.skipWaiting();

      await caches.delete(STATIC_RUNTIME_CACHE);
      await deleteOldPrecache();

      try {
        // unfortunately, chrome only recalculates the cache usage after closing and reopening the tab
        // if we exceeded the cache quota, resetting the caches is not enough. To ensure we can install the update
        // we wrap this in a try/catch block and ensure the old precache gets emptied
        // in a worst case scenario, the precache files will be stored in the static runtime cache, which isn't a big problem
        await addToPrecache(precache, PRECACHE_URLS);
      } catch (error) {
        logger.info("Failed to precache, possible cache quota limit?:", error);
      }
    })()
  );
};

const handleActivateEvent = (event: ExtendableEvent) => {
  logger.info("Installation complete. Activating ...");

  event.waitUntil(
    (async () => {
      await self.clients.claim();

      logger.info("Activation complete. Reloading ...");

      // reset caches again, to ensure no api breaking requests are cached
      await caches.delete(STATIC_RUNTIME_CACHE);
    })()
  );
};

const handleFetchEvent = async (event: FetchEvent) => {
  const isStaticRequest = (request: Request): boolean => {
    const pathname = request.url.replace(self.location.origin, "");

    return !API_PATHS.some(apiPath => pathname.startsWith(apiPath));
  };

  const getStaticResponse = async (): Promise<Response> => {
    const cachedResponse = await caches.match(event.request);

    if (cachedResponse) {
      return cachedResponse;
    } else {
      const runtimeCache = await caches.open(STATIC_RUNTIME_CACHE);
      const response = await fetch(event.request);

      try {
        if (response.ok) {
          await runtimeCache.put(event.request, response.clone());
        }
      } catch (error) {
        // this should not happen, is the cache quota very low?
        logger.warn("Quota exceeded", error);
      }

      return response;
    }
  };

  // Skip cross-origin requests
  if (event.request.url.startsWith(self.location.origin)) {
    if (isStaticRequest(event.request)) {
      event.respondWith(getStaticResponse());
    } else {
      event.respondWith(fetch(event.request));
    }
  }
};

// we don't want the service worker to install during development
// to test this setup, build the mobile app in production mode,
// or in a best case scenario, follow the Local BikeCenter Mobile Integration Setup
// instructions, that are written in the MOBILE.md
if (process.env.NODE_ENV === "production") {
  // The install handler takes care of precaching the resources we always need.
  self.addEventListener("install", handleInstallEvent);

  // The activate handler takes care of cleaning up old caches.
  self.addEventListener("activate", handleActivateEvent);

  // The fetch handler serves responses for same-origin resources from a cache.
  // If no response is found, it populates the runtime cache with the response
  // from the network before returning it to the page.
  self.addEventListener("fetch", handleFetchEvent);
}

export default null;
declare const self: ServiceWorkerGlobalScope;
